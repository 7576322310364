import React, { useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../components/cmp_icon';

import './crd_mfa.css';



export default function CRD_MFA() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_stm_state, set_stm_state ] = useState('INACTIVE');
    const [ var_stm_enabled, set_stm_enabled ] = useState(false);
    const [ var_error_msg, set_error_msg ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='mfa__card'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Multi-factor authentication (MFA)</div>
                    <div className='text--sm-regular'>Increase security by enabling multi-factor authentication for your account.</div>
                </div>
                {render_enable_stm()}
            </div>
            {var_error_msg &&
                <Message error
                    id='mfa_error_message'
                    icon={<Icon name='error' className='message__error__icon mfa' />}
                    header={var_error_msg}
                />
            }
            {render_stm_step()}
        </div>
    );

    function render_stm_step() {
        return (
            <div className='card__content'>
                <Form>
                    <div className='card__footer'>
                        <div className='--text-xs-bold'>NOT IMPLEMENTED</div>
                        <div className='card__footer__btns' style={{ marginTop: '2rem' }}>
                            <Button className='secondary'>Enable MFA</Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }

    function render_enable_stm() {
        if (var_stm_enabled) {
            return (
                <div className='card__header__right'>
                    <div className='badge centered badge--green'>Enabled</div>
                </div>
            );
        } else if (var_stm_state === 'INACTIVE' ) {
            return (
                <div className='card__header__right card__header__btns'>
                    <Button className='secondary'>Enable MFA</Button>
                </div>
            );
        }
    }
};