import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import auth from '../../../../libs/auth-lib';

import './crd_change_password.css';

export default function CRD_CHANGE_PASSWORD() {

    //  variable declarations ------------------------------------------------------------------------------------------

    //const [ var_processing, set_processing ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------


    //  functions ------------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // function onClick_cancel() {
    //    set_mode('VIEW');
    //}

    //function onClick_save() {
      //  if (var_processing) return;

        // save_record();
    //}

    function onClick_change_password() {
        auth.iam.change_password();
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='card_change_password'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Change password</div>
                    <div className='text--sm-regular'>You will be redirected to Keycloak to change your password.</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button className='secondary' onClick={() => onClick_change_password()}>Change password</Button>
                </div>
            </div>
            <div className='card__footer'>
                <div className='card__footer__btns'>
                        <Button className='secondary' onClick={() => onClick_change_password()}>Change password</Button>
                </div>
            </div>
        </div>
    );
};