import React from 'react';

import CRD_CHANGE_PASSWORD from './crd_change_password/crd_change_password';
import CRD_MFA from './crd_mfa/crd_mfa';



export default function TAB_SECURITY() {

    //  variable declarations ------------------------------------------------------------------------------------------

    //  event listeners ------------------------------------------------------------------------------------------------

    //  functions ------------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return (
        <div className='card__content'>
            <CRD_CHANGE_PASSWORD />
            <CRD_MFA />
        </div>
    );
};